<template>
    <PageTitle pageTitle="Memorial Details" aria-label="Page Title"> </PageTitle>

    <v-card elevation="5" @click="toggle" role="article" :aria-label="'Memorial' + memorialView.name + 'details'">
        <v-card-title
            class="text-orange-darken-4 text-capitalize font-weight-bold text-center text-h6"
            data-cy="memorial_view_name"
            :aria-label="'Memorial Name is: ' + memorialView.name "
        >
            {{ memorialView.name }}
        </v-card-title>

        <v-card-subtitle>
            <v-row
                class="text-green-darken-4 text-capitalize font-weight-bold text-center text-subtitle-1"
                data-cy="founder_name"
            >
                <v-col :aria-label="'Memorial Founder name is:' + memorialView.founder_name">{{ memorialView.founder_name }}</v-col>
            </v-row>
        </v-card-subtitle>

        <v-divider />
        <br />

        <v-row>
            <v-col
                :lg="displayCarousel(photoLinks) ? 4 : 12"
                :md="displayCarousel(photoLinks) ? 4 : 12"
                cols="12"
                sm="12"
            >
                <v-card-text class="scrollable-card-text">
                    <div class="info-container" aria-label="Memorial informations">
                        <div v-if="memorialView.memorial_type_desc" class="info-box">
                            <strong> Type </strong>
                            <span data-cy="memorial_type_desc">{{ memorialView.memorial_type_desc }}</span>
                        </div>

                        <div v-if="memorialView.type" class="info-box">
                            <strong> Memorial Type </strong>
                            <span class="text-capitalize" data-cy="type" :aria-label="'Memorial Type is: ' + memorialView.type"
                                >{{ memorialView.type }}</span>
                        </div>

                        <div v-if="this.mem_location_type" class="info-box">
                            <strong> Memorial Location Type </strong>
                            <span >{{ memorialView.mem_location.type }}</span>
                        </div>

                        <div v-if="this.mem_location_permanent" class="info-box">
                            <strong> Permanent or Temporary </strong>
                            <span class="text-capitalize" data-cy="mem_location_permanent"
                                >{{ memorialView.mem_location.permanent }}</span>
                        </div>

                        <div v-if="memorialView.founder_name" class="info-box">
                            <strong> Founder Name </strong>
                            <span data-cy="founder_name2"
                                  :aria-label="'Memorial Founder name is: ' + memorialView.founder_name"
                            >{{ memorialView.founder_name }}</span>
                        </div>

                        <div v-if="memorialView.email" class="info-box">
                            <strong> Email </strong>
                            <span data-cy="email"
                            >{{ memorialView.email }}</span>
                        </div>

                        <div v-if="memorialView.website" class="info-box">
                            <strong> Website </strong>
                            <a
                                target="_blank"
                                data-cy="website"
                                :aria-label="'Memorial Website is: ' + memorialView.website"
                                v-bind:href="memorialView.website"
                                >{{ memorialView.website < 20 ?
                                memorialView.website :
                                memorialView.website.substring(0, 40) + "..."
                                }}</a
                            >
                        </div>

                        <div v-if="this.startdate" class="info-box">
                            <strong> Start Date </strong>
                            <span data-cy="startdate" :aria-label="'Memorial Start on: ' + this.startdate"
                            >{{ this.startdate }}</span>
                        </div>

                        <div v-if="this.enddate" class="info-box">
                            <strong> End Date </strong>
                            <span data-cy="enddate" :aria-label="'Memorial End on: ' + this.enddate"
                            >{{ this.enddate }}</span>
                        </div>
                        <br />

                        <div
                            v-if="memorialView.google_virtual_tour"
                            class="info-box"
                        >
                            <strong>Virtual Tour:</strong>
                            <a
                                target="_blank"
                                data-cy="google_virtual_tour"
                                v-bind:href="memorialView.google_virtual_tour"
                                >{{ memorialView.google_virtual_tour < 40 ?
                                memorialView.google_virtual_tour :
                                memorialView.google_virtual_tour.substring(0,
                                40) + "..." }}</a
                            >
                        </div>
                        <div
                            v-if="memorialView.social_media_facebook"
                            class="info-box"
                        >
                            <strong> Facebook: </strong>
                            <a
                                target="_blank"
                                data-cy="social_media_facebook"
                                aria-label="Memorial Facebook account"
                                v-bind:href="memorialView.social_media_facebook"
                                >{{ memorialView.social_media_facebook < 40 ?
                                memorialView.social_media_facebook :
                                memorialView.social_media_facebook.substring(0,
                                40) + "..." }}</a
                            >
                        </div>
                        <div
                            v-if="memorialView.social_media_twitter"
                            class="info-box"
                        >
                            <strong> Twitter: </strong>
                            <a
                                target="_blank"
                                data-cy="social_media_twitter"
                                aria-label="Memorial Twitter account"
                                v-bind:href="memorialView.social_media_twitter"
                                >{{ memorialView.social_media_twitter < 40 ?
                                memorialView.social_media_twitter :
                                memorialView.social_media_twitter.substring(0,
                                40) + "..." }}</a
                            >
                        </div>
                        <div
                            v-if="memorialView.social_media_instagram"
                            class="info-box"
                        >
                            <strong> Instagram: </strong>
                            <a
                                target="_blank"
                                data-cy="social_media_instagram"
                                aria-label="Memorial Instagram account"
                                v-bind:href="memorialView.social_media_instagram"
                                >{{ memorialView.social_media_instagram < 20 ?
                                memorialView.social_media_instagram :
                                memorialView.social_media_instagram.substring(0,
                                40) + "..." }}</a
                            >
                        </div>
                    </div>
                </v-card-text>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="12">
                <v-card-text class="scrollable-card-text">
                    <div data-cy="description" style="font-size: 15px" :aria-label="'Memorial Description is: ' + memorialView.description">
                        {{ memorialView.description }}
                    </div>
                </v-card-text>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" v-if="displayCarousel(photoLinks)">
              <v-carousel
                data-cy="Image"
                height="300"
                width="550"
                :show-arrows="photoLinks.length > 1 ? 'hover' : false"
                cycle
                cyle-interval="4000"
                hide-delimiter-background
                :hide-delimiters="photoLinks.length < 2"
                cover
                role="region"
                :aria-label="'This memorial contains' + photoLinks.length + 'images'"
                aria-roledescription="carousel"
                aria-live="polite"
              >
                <v-carousel-item
                    v-for="photoUrl in photoLinks"
                    :src="photoUrl"
                    :alt="memorialView.name + 'Image'"
                    role="img"
                    aria-roledescription="slide"
                    aria-label="Memorial Image">
                </v-carousel-item>
              </v-carousel>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" lg="8" md="6" sm="12">
                <v-card-text class="text-body-1" aria-label="Associated Media Links section">
                    <div
                        class="text-blue-darken-4 font-weight-bold"
                        data-cy="associated_links"
                        role="heading"
                        aria-label="Associated Media Links"
                    >
                        Associated Media Links
                    </div>
                    <br />
                    <v-table fixed-header class="bordered-table">
                        <thead>
                            <tr>
                                <th class="text-left" style="font-size: 14px;">Media Type</th>
                                <th class="text-left" style="font-size: 14px;">Media Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td aria-label="These are the list of video links"><strong>Video</strong></td>
                                <td v-if="videoLinks.length > 0">
                                    <div class="scrollable-list">
                                        <template v-for="item in videoLinks">
                                            <a :href="item.href" target="_blank">{{ item.text }}</a><br />
                                        </template>
                                    </div>
                                </td>
                                <td v-else aria-label="There are no video available for this memorial">
                                    N/A
                                </td>
                            </tr>
                            <tr>
                                <td aria-label="These are the list of photo links"><strong>Photo</strong></td>
                                <td v-if="photoMediaLinks.length > 0">
                                    <div class="scrollable-list">
                                        <template v-for="item in photoMediaLinks">
                                            <a :href="item.href" target="_blank">{{ item.text }}</a><br />
                                        </template>
                                    </div>
                                </td>
                                <td v-else aria-label="There are no photo available for this memorial">
                                    N/A
                                </td>
                            </tr>
                            <tr>
                                <td aria-label="These are the list of press coverage links"><strong>Press Coverage</strong></td>
                                <td v-if="pressLinks.length > 0">
                                    <div class="scrollable-list">
                                        <template v-for="item in pressLinks">
                                            <a :href="item.href" target="_blank">{{ item.text }}</a><br />
                                        </template>
                                    </div>
                                </td>
                                <td v-else aria-label="There are no press coverage available for this memorial">
                                    N/A
                                </td>
                            </tr>
                      </tbody>
                    </v-table>
                </v-card-text>
            </v-col>
            <v-col align="center" cols="12" lg="4" md="4" sm="12">
                <div class="text-blue-darken-4 font-weight-bold" role="heading" aria-label="Memorial Location">
                    Memorial Location
                </div>
                <div class="text-orange-darken-4 font-weight-bold">
                    <strong :aria-label="'The memorial address is: ' + address">{{ address }}</strong>
                </div>
                <div
                    v-if="this.pos.lng !== '' && this.pos.lat !== ''"
                    id="location_m"
                    class="align-center"
                    role="complementary"
                >
                    <GMapMap
                        :center="this.pos"
                        :zoom="15"
                        map-type-id="terrain"
                        style="width: 550px; height: 300px"
                        aria-label="Map showing the location of the memorial at the specified address"
                    >
                        <GMapMarker
                            :clickable="true"
                            :draggable="true"
                            :position="this.pos"
                            aria-label="Memorial Position Marker"
                        >
                        </GMapMarker>
                    </GMapMap>
                </div>
                <div
                    v-if="this.pos.lng === '' && this.pos.lat === ''"
                    id="location_m"
                    class="align-center"
                >
                    <GMapMap
                        :center="{ lat: 41.25716, lng: -95.995102 }"
                        :zoom="15"
                        map-type-id="terrain"
                        style="width: 400px; height: 300px"
                        aria-label="Default map view, as the specific location is not provided."
                    >
                    </GMapMap>
                </div>
            </v-col>
        </v-row>

        <br />
        <v-divider />

        <v-card-actions>
            <v-row>
                <v-col align="center" cols="12" lg="6" md="6" sm="12">
                    <router-link :to="{ name: 'updatememorial' }">
                        <v-btn
                            class="text-blue"
                            data-cy="edit_memorial"
                            elevation="2"
                            size="large"
                            @click="setpk(memorialView.id)"
                            role="button"
                            aria-label="Edit the memorial details"
                        >
                            Edit Memorial
                        </v-btn>
                    </router-link>
                </v-col>
                <v-col align="center" cols="12" lg="6" md="6" sm="12" role="group" aria-label="Social Media Share Buttons">
                    <ShareNetwork
                      network="facebook"
                      ref="facebook"
                      :url="shareUrl"
                      :title="socialMediaTitle"
                      :description="socialMediaDescription"
                      :hashtags="socialMediaHashtag"
                      data-cy="facebook_share"
                      class="share-button text-blue justify-center"
                      role="button"
                      aria-label="Share on Facebook"
                    >
                      <v-icon size="20" aria-hidden="true">mdi-facebook</v-icon>
                      Share via Facebook
                    </ShareNetwork>
                    <ShareNetwork
                      network="twitter"
                      :url="shareUrl"
                      :title="socialMediaTitle"
                      :description="socialMediaDescription"
                      :hashtags="socialMediaHashtag"
                      data-cy="twitter_share"
                      class="share-button text-blue justify-center"
                      role="button"
                      aria-label="Share on Twitter"
                    >
                      <v-icon size="20" aria-hidden="true">mdi-twitter</v-icon>
                      Share via Twitter
                    </ShareNetwork>
                    <ShareNetwork
                      network="whatsapp"
                      :url="shareUrl"
                      :title="socialMediaTitle"
                      :description="socialMediaDescription"
                      :hashtags="socialMediaHashtag"
                      data-cy="whatsapp_share"
                      class="share-button text-blue justify-center"
                      role="button"
                      aria-label="Share on WhatsApp"
                    >
                      <v-icon size="20" aria-hidden="true">mdi-whatsapp</v-icon>
                      Share via WhatsApp
                    </ShareNetwork>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
    import PageTitle from "@/components/PageTitle.vue";
    import { APIService } from "@/http/APIService";
    import VueSocialSharing from 'vue-social-sharing';

    const apiService = new APIService();
    export default {
      components: {
        PageTitle,
        VueSocialSharing,
      },
      name: "MemorialDetailView",
      data: () => ({
        memorialView: {},
        pos: { lat: "", lng: "" },
        startdate: "",
        enddate: "",
        address: "",
        pressLinks: [],
        videoLinks: [],
        photoMediaLinks: [],
        medialLinks: {},
        valid: true,
        mem_location_type: "",
        mem_location_permanent: "",
        photoLinks: new Array(),
        defaultMemorialPhotoUrl: null,
        VideoLinks:null,
        PhotoLinks:null,
        PresCoverage:null,
        selectedOption:null,
        shareUrl: window.location.href,
        socialMediaTitle: "",
        socialMediaHashtag: "",
        socialMediaDescription: "",
      }),
      mounted() {
        this.getMemorialView();
        this.getMediaLinks();
        this.getPhotoLinks();
        this.getMemorialDefaultPhotoUrl();
        this.getSocialMediaHashtag();
        this.getSocialMediaTitle();
        this.getSocialMediaDescription();
      },
      methods: {
        getMemorialView() {
          this.memorialid = this.$route.params.id;
          apiService.getMemorialView(this.memorialid).then((res) => {
            this.memorialView = res.data;
            if (
              this.memorialView.mem_location.lat_coord !== null &&
              this.memorialView.mem_location.long_coord !== null
            ) {
              this.pos.lat = parseFloat(this.memorialView.mem_location.lat_coord);
              this.pos.lng = parseFloat(this.memorialView.mem_location.long_coord);
            }
            if (this.memorialView.mem_location !== null) {
              this.mem_location_type = this.memorialView.mem_location.type;
              this.mem_location_permanent =
                this.memorialView.mem_location.permanent;
              let street_addr =
                this.memorialView.mem_location.address !== null
                  ? this.memorialView.mem_location.address + ", "
                  : "";
              let city =
                this.memorialView.mem_location.city !== null
                  ? this.memorialView.mem_location.city + ", "
                  : "";
              let state =
                this.memorialView.mem_location.state !== null
                  ? this.memorialView.mem_location.state
                  : "";
              this.address = street_addr + city + state;
            }
            this.startdate = this.memorialView.mem_location.time_active_start;
            let month,
              day,
              year = "";
            let formStrDate,
              ddmmyyyyStrDate = "";
            this.startdate !== null
              ? ([year, month, day] = this.startdate.split("-"))
              : console.log("start date empty");
            if (this.startdate !== null) {
              formStrDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
                2,
                "0"
              )}`;
              ddmmyyyyStrDate = `${month.padStart(2, "0")}/${day.padStart(
                2,
                "0"
              )}/${year}`;
              this.startdate = ddmmyyyyStrDate;
            } else {
              console.log("start date still empty :-/");
            }

            this.enddate = this.memorialView.mem_location.time_active_end;
            let formEndDate,
              ddmmyyyyEndDate = "";
            this.enddate !== null || this.enddate === ""
              ? ([year, month, day] = this.enddate.split("-"))
              : console.log("end date empty");
            if (this.enddate !== null || this.enddate === "") {
              formEndDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
                2,
                "0"
              )}`;
              ddmmyyyyEndDate = `${month.padStart(2, "0")}/${day.padStart(
                2,
                "0"
              )}/${year}`;
              this.enddate = ddmmyyyyEndDate;
            } else {
              console.log("end date still empty :-/");
            }
          });
        },
        getMediaLinks() {
          this.memorialid = this.$route.params.id;
          apiService.getMediaLinksView(this.memorialid).then((res) => {
            this.medialLinks = res.data;
          for (let key in this.medialLinks){
            let media = this.medialLinks[key]
            console.log(media.url)
            let url = media.url
            const newOption = {
              text: url,
              href: url,
              label: '<a href="' + url + '" target="_blank">' + url + '</a>',
          };
            if (media.type === 'photo'){
              this.photoMediaLinks.push(newOption)
            }
             if (media.type === 'video'){
               console.log(newOption);
              this.videoLinks.push(newOption)
            }
              if (media.type === 'press coverage'){
              this.pressLinks.push(newOption)
            }
          }
          });
        },
        getPhotoLinks() {
          this.memorialid = this.$route.params.id;
          apiService
            .getMemorialProfilePhotoUrl(this.memorialid)
            .then((memorialProfilePhotoLinkUrlResponse) => {
              this.photoLinks = memorialProfilePhotoLinkUrlResponse.data.data;
              if (this.photoLinks.length === 0 && this.defaultMemorialPhotoUrl) {
                this.photoLinks.push(this.defaultMemorialPhotoUrl);
              }
            });
        },
        getMemorialDefaultPhotoUrl() {
          apiService
            .getWebsiteConfigurationParameter("MEMORIAL_DEFAULT_PICTURE_URL")
            .then((res) => {
              this.defaultMemorialPhotoUrl = res.data.data;
            });
        },
        getSocialMediaTitle() {
          apiService
            .getWebsiteConfigurationParameter("SOCIAL_MEDIA_TITLE")
            .then((res) => {
              this.socialMediaTitle = res.data.data.replace('{{MEMORIAL_NAME}}', this.memorialView.name);
            });
        },
        getSocialMediaHashtag() {
          apiService
            .getWebsiteConfigurationParameter("SOCIAL_MEDIA_HASHTAGS")
            .then((res) => {
              this.socialMediaHashtag = res.data.data;
            });
        },
        getSocialMediaDescription() {
          apiService
            .getWebsiteConfigurationParameter("SOCIAL_MEDIA_DESCRIPTION")
            .then((res) => {
              this.socialMediaDescription = res.data.data.replace('{{MEMORIAL_NAME}}', this.memorialView.name);
            });
        },
        setpk(id) {
          localStorage.setItem("id", id);
        },
        prepareUrl(url) {
          if (url != null && !url.startsWith("http")) {
            return "https://" + url;
          }
          return url;
        },
        displayCarousel(photoLinks) {
          return photoLinks.length > 0;
        },
      },
    };
</script>

<style>
    .bordered-table {
        border: 1px solid #ddd;
    }
    .scrollable-card-text {
        max-height: 300px;
        overflow-y: auto;
    }
    .scrollable-list {
        max-height: 80px;
        overflow-y: auto;
    }
    .info-container {
        display: flex;
        flex-direction: column;
        border: 1px solid #ccc;
        padding: 8px;
        margin-bottom: 8px;
    }
    .info-box {
        display: flex;
        border: 1px solid #ccc;
        padding: 8px;
        margin-bottom: 8px;
    }
    .info-box strong {
        border-right: 1px solid #ccc;
        padding-right: 8px;
        margin-right: 8px;
        width: 100px;
    }
    .info-box span {
        flex: 1;
        margin-left: 8px;
    }
    .share-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        padding: 6px 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.2s, box-shadow 0.2s;
        text-decoration: none;
    }
</style>