<template>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap"
    rel="stylesheet"
  />
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo+Narrow&display=swap"
    rel="stylesheet"
  />

  <PageTitle pageTitle="Submit Memorial" page />

  <div id="SubmitMemorial" role="form" aria-labelledby="SubmitMemorial" aria-label="Submit Memorial Page">
    <v-container fluid>
      <v-row justify="center" class="row bg-grey-lighten-3 text-md-center">
        <v-col cols="auto">
          <v-card elevation="5">
            <v-card-title class="text-wrap sub_memorial_css2" aria-labelledby="Please provide your information" aria-label="Please provide your information below">
              Please provide your information:
            </v-card-title>
          </v-card>
          <br />
          <v-form ref="form" v-model="valid" lazy-validation role="form" aria-labelledby="Please provide your information">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="first_name"
                  v-model="firstname"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  :rules="nameRules"
                  label="First Name*"
                  counter="50"
                  clearable
                  required
                  aria-label="Please enter your First Name"
                  role="textbox"
                  aria-required="true"
                  aria-describedby="Please enter your first name. It should be less than 50 characters."
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="last_name"
                  aria-label="Please enter your Last Name"
                  role="textbox"
                  aria-required="true"
                  aria-describedby="Please enter your last name. It should be less than 50 characters."
                  v-model="lastname"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  :rules="nameRules"
                  label="Last Name*"
                  :counter="50"
                  clearable
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="youremail"
                  aria-label="Please enter your Email Address"
                  role="textbox"
                  aria-required="true"
                  aria-describedby="Please enter your Email Address."
                  v-model="youremail"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  :rules="emailRules"
                  label="Email*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="yzip"
                  aria-label="Please enter your Zip code"
                  role="textbox"
                  aria-required="true"
                  aria-describedby="Please enter your Zip code."
                  v-model="yzip"
                  label="Zip*"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  :rules="zipRules"
                  counter="5"
                  clearable
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" :md="6" :style="{ 'font-family': 'Archivo Narrow, sans-serif' }">
                <v-card>
                  <v-list-item>
                    <v-list-item-action
                    aria-label="Did you lose anyone close to you due to Covid-19? If yes please check the box, if not leave it unchecked."
                    aria-checked="false"
                    role="checkbox"
                    >
                      <v-checkbox
                        data-cy="covid_checkbox"
                        v-model="loss"
                        color="green"
                        label="Did you lose anyone close to you due to Covid-19?"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col :cols="12" :md="6" :style="{ 'font-family': 'Archivo Narrow, sans-serif' }">
                <v-card>
                  <v-list-item>
                    <v-list-item-action
                    aria-label="Are you affiliated with Marked By Covid? If yes please check the box, if not leave it unchecked"
                    aria-checked="false"
                    role="checkbox"
                    >
                      <v-checkbox
                        data-cy="covid_aff_checkbox"
                        v-model="affiliated"
                        color="green"
                        label="Are you affiliated with Marked By Covid?"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card elevation="5">
                  <v-card-title class="text-wrap sub_memorial_css2" aria-labelledby="Memorial Details" aria-label="Please enter the memorial details below"
                    >Memorial Details:</v-card-title
                  >
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap sub_memorial_css"
                        style="text-align: left"
                        role="heading"
                      aria-label="If you are unsure about the official name of the memorial,
                      please type a brief description in below box, example:I-95 Roadside Memorial"
                      >
                        If you do not know the official name of the memorial,
                        please put a brief description (eg. “I-95 Roadside
                        Memorial”)*
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-textarea
                        data-cy="memorial_name"
                        aria-label="Please enter the name of Memorial Name"
                        role="textbox"
                        aria-required="true"
                        aria-describedby="Memorial Name"
                        auto-grow
                        clearable
                        v-model="memorialname"
                        variant="solo"
                        bg-color="grey-lighten-4"
                        class="sub_memorial_css"
                        :rules="memorialnameRules"
                        counter="256"
                        label="Memorial Name*"
                        required
                      ></v-textarea>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>

              <v-col cols="12" md="3">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap sub_memorial_css"
                        style="text-align: left"
                        aria-label="Please describe the memorial, including any artist’s statement or rationale. This information helps provide context and understanding."
                      >
                        Please provide a description of the memorial. (This may
                        include an artist’s statement or rationale.)*
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-textarea
                        data-cy="memorial_description"
                        aria-label="Please enter Description of the Memorial"
                        role="textbox"
                        aria-required="true"
                        aria-describedby="Memorial Description"
                        auto-grow
                        clearable
                        v-model="description"
                        variant="solo"
                        bg-color="grey-lighten-4"
                        class="sub_memorial_css"
                        type="description"
                        :rules="descriptionRules"
                        counter="4096"
                        label="Memorial Description*"
                        required
                      ></v-textarea>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap sub_memorial_css"
                        style="text-align: left"
                        role="heading"
                        aria-label="Please provide the name of the contact or founder associated with this memorial in below space. This information helps provide context and understanding."
                      >
                        Please provide the name of the founder for this memorial
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-textarea
                        data-cy="founder_name"
                        v-model="foundername"
                        variant="solo"
                        class="sub_memorial_css"
                        bg-color="grey-lighten-4"
                        :rules="founderRulesNot"
                        counter="75"
                        label="Contact/Founder"
                        clearable
                        role="textbox"
                        aria-label="Please enter Contact or Founder Name"
                        aria-describedby="founderNameDescription"
                      ></v-textarea>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>

              <v-col cols="12" md="3">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap sub_memorial_css"
                        style="text-align: left"
                        role="heading"
                        aria-label="Please enter keywords for the memorial here. For example, #memorialidentifier. This information helps with categorization and searching."
                      >
                        Please enter your keywords for the memorial here (eg. "#memorialidentifier")
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-textarea
                        data-cy="keywords"
                        role="textbox"
                        aria-label="Please enter keywords for the memorial here."
                        aria-describedby="keywordsDescription"
                        auto-grow
                        clearable
                        v-model="keywords"
                        variant="solo"
                        bg-color="grey-lighten-4"
                        class="sub_memorial_css"
                        type="keywords"
                        counter = "75"
                        label=" Keywords "
                      ></v-textarea>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-card>
                  <v-list-item>
                    <!--                    <v-list-item-content>-->
                    <!--                      <v-list-item-title class="text-wrap, sub_memorial_css" style="text-align:left" >Is this memorial part of a Covid Memorial Day observance?</v-list-item-title>-->
                    <!--                    </v-list-item-content>-->
                    <v-list-item-action
                    aria-label="Is this memorial part of a Covid Memorial Day observance? If Yes, Please check the box if not skip to next"
                    aria-checked="false"
                    role="checkbox"
                    >
                      <v-checkbox
                        data-cy="covid_obs_checkbox"
                        v-model="observance"
                        color="green"
                        label="Is this memorial part of a Covid Memorial Day observance?"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-card>
                  <v-list-item>
                    <v-list-item-content
                    aria-label="You can use the auto-complete address field below or open the map to enter the address."
                    role="heading"
                    >
                      <v-list-item-title
                        class="text-wrap sub_memorial_css"
                        style="text-align: left"
                        >Please use the auto complete address field below or use
                        open map to enter the address</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <GMapAutocomplete
                        data-cy="mem_location"
                        @place_changed="setPlace"
                      >
                        <!-- <template v-slot:input="slotProps">
                    <v-text-field
                      ref="input"
                      v-bind="slotProps"
                      prepend-inner-icon="mdi-map-marker"
                      hide-details
                      clearable
                      @click:clear="clearAddress()"
                    />
                  </template> -->
                      </GMapAutocomplete>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="street"
                  aria-label="Please enter the Street Address"
                  v-model="street"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  label="Street Address"
                  clearable
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  data-cy="city"
                  aria-label="Please enter the City Name"
                  v-model="city"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  label="City"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  data-cy="state"
                  aria-label="Please enter the State Name"
                  v-model="state"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  label="State"
                  clearable
                  persistent-hint
                  hint="Two Letter Code"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  data-cy="zip"
                  aria-label="Please enter the Zipcode"
                  v-model="zip"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  label="Zip"
                  clearable
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md>
                <v-card class="overflow-y-auto">
                  <v-list-item>
                    <v-list-item-content
                    aria-label="If you are unable to provide a street address, you can use this link to drop a pin on the map to indicate the location."
                    >
                      <v-list-item-title
                        class="text-wrap sub_memorial_css"
                        style="text-align: left"
                        >If you do not know the street address, use this link to
                        drop a pin.</v-list-item-title
                      >
                    </v-list-item-content>
                    <br />
                    <v-list-item-action>
                      <v-btn
                        flat
                        @click="dialog = true"
                        color="green"
                        variant="outlined"
                        append-icon="mdi-google-maps"
                        aria-label="click on Open Map Button to open maps"
                        role="landamrk"
                        >Open Map
                      </v-btn>
                      <v-dialog v-model="dialog" width="unset" aria-label="Google Maps Dialog">
                        <v-card>
                          <v-card-text>
                            <LatLongPicker
                              :initialLat="latitude"
                              :initialLng="longitude"
                              @mapClickLatLong="handleMapClickLatLong"
                              @fetchAddress="handleFetchAddress"
                            />
                          </v-card-text>
                          <v-card-actions
                          aria-label="please use the pin to point the location and then click here to close this dialogue"
                          >
                            <v-btn color="primary" block @click="dialog = false"
                              >Please Pin & Click on Marker and Close
                              Dialog</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  data-cy="mem_type"
                  v-model="mem_type"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  label="Type"
                  hint="Example: website, monument, plaque"
                  persistent-hint
                  clearable
                  role="textbox"
                  aria-label="Please enter the type of the memorial. For example, website, monument, plaque."
                  aria-describedby="typeDescription"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-list-item-action
                    aria-label="Is this a virtual Memorial? If yes please check the box, if not leave it unchecked"
                    aria-checked="false"
                    role="checkbox"
                    >
                <v-checkbox
                  data-cy="vcheckbox"
                  v-model="vcheckbox"
                  class="sub_memorial_css"
                  color="green"
                  label="Virtual"
                ></v-checkbox>
                </v-list-item-action>
              </v-col>
              <v-col cols="6" md="6">
                <v-list-item-action
                    aria-label="Is this a Temporary Memorial? If yes please check the box, if not leave it unchecked"
                    aria-checked="false"
                    role="checkbox"
                    >
                <v-checkbox
                  data-cy="temp_checkbox"
                  v-model="tempcheckbox"
                  class="sub_memorial_css"
                  color="green"
                  label="Temporary"
                ></v-checkbox>
                </v-list-item-action>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="date"
                  v-model="date"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  :rules="dateRulesNot"
                  label="Memorial Start Date"
                  hint="MM/DD/YYYY"
                  persistent-hint
                  aria-label="Please enter the start date of the memorial in the format MM/DD/YYYY. Leave blank if the Start date is unknown."
                  role="textbox"
                  aria-describedby="Start Date"
                >
                </v-text-field>

                <p style="font-family: 'Archivo Narrow', sans-serif" aria-label="Leave blank if unknown">
                  Leave blank if unknown
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="end_date"
                  v-model="enddate"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  :rules="dateRulesNot"
                  label="Memorial End Date"
                  hint="MM/DD/YYYY"
                  persistent-hint
                  aria-label="Please enter the end date of the memorial in the format MM/DD/YYYY. Leave blank if the end date is unknown."
                  role="textbox"
                  aria-describedby="End Date"
                ></v-text-field>
                <p style="font-family: 'Archivo Narrow', sans-serif" aria-label="Leave blank if not applicable">
                  Leave blank if not applicable
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="memorial_website"
                  v-model="memorialwebsite"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  :rules="[
                    (v) =>
                      !v || !v.trim() || this.isURL(v) || 'URL is not valid',
                  ]"
                  label="Website Link"
                  aria-label="Please enter the website link for the memorial. Leave blank if not applicable."
                  role="textbox"
                  aria-describedby="websiteLinkDescription"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="memorial_email"
                  v-model="memorialemail"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  :rules="emailRulesNot"
                  label="Email"
                  aria-label="Please enter the email address for the memorial. Leave blank if not applicable."
                  role="textbox"
                  aria-describedby="emailDescription"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="memorial_profile_link"
                  v-model="memorialphoto"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  :rules="[
                    (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                  ]"
                  label="Profile Photo Link"
                  persistent-hint
                  hint="Add a profile photo link here"
                  aria-label="Please enter the profile photo link for the memorial. Leave blank if not applicable."
                  role="textbox"
                  aria-describedby="profilePhotoLinkDescription"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="virtual_tour"
                  v-model="virtualtour"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  :rules="[
                    (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                  ]"
                  label="Virtual Tour Link"
                  aria-label="Please enter the virtual tour link for the memorial. Leave blank if not applicable."
                  role="textbox"
                  aria-describedby="virtualTourLinkDescription"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <div
                  v-for="(item, index) in photoLinks"
                  :key="index"
                  class="d-flex"
                >
                  <v-text-field
                    data-cy="photo_links"
                    aria-label="Please enter the photo link for the memorial. Leave blank if not applicable."
                    role="textbox"
                    aria-describedby="Photolink"
                    v-model="item.purl"
                    variant="solo"
                    bg-color="grey-lighten-4"
                    class="sub_memorial_css"
                    persistent-hint
                    hint="Add a photo link here"
                    label="Photo Link"
                    :rules="[
                      (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                    ]"
                  >
                  </v-text-field>
                  <v-icon
                    icon="mdi-close"
                    color="red"
                    @click="removePhotoLink(index)"
                    v-show="index != 0"
                    aria-label="Remove Photo Link"
                  ></v-icon>
                </div>
                <v-btn
                  flat
                  border
                  elevation="0"
                  variant="outlined"
                  color="green"
                  prepend-icon="mdi-plus"
                  @click="addRow"
                  aria-label="Add Photo Link Button"
                  >Add Photo Link</v-btn
                >
              </v-col>

              <v-col cols="12" md="4">
                <div
                  v-for="(item, index) in videoLinks"
                  :key="index"
                  class="d-flex"
                >
                  <v-text-field
                    data-cy="video_links"
                    aria-label="Please enter the video link for the memorial. Leave blank if not applicable."
                    aria-describedby="videoLinkDescription"
                    role="textbox"
                    v-model="item.vurl"
                    variant="solo"
                    bg-color="grey-lighten-4"
                    class="sub_memorial_css"
                    persistent-hint
                    hint="Add a video link here"
                    label="Video Link"
                    :rules="[
                      (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                    ]"
                  >
                  </v-text-field>
                  <v-icon
                    icon="mdi-close"
                    color="red"
                    @click="removeVideoLink(index)"
                    v-show="index != 0"
                    aria-label="Remove Video Link"
                  ></v-icon>
                </div>
                <v-btn
                  flat
                  border
                  elevation="0"
                  variant="outlined"
                  color="green"
                  prepend-icon="mdi-plus"
                  @click="addvLink"
                  aria-label="Add Video Link Button"
                  >Add Video Link</v-btn
                >
              </v-col>

              <v-col cols="12" md="4">
                <div
                  v-for="(item, index) in pressLinks"
                  :key="index"
                  class="d-flex"
                >
                  <v-text-field
                    data-cy="press_links"
                    aria-label="Please enter the press coverage link for the memorial. Leave blank if not applicable."
                    aria-describedby="pressLinkDescription"
                    role="textbox"
                    v-model="item.pcurl"
                    variant="solo"
                    bg-color="grey-lighten-4"
                    class="sub_memorial_css"
                    persistent-hint
                    hint="Add a press coverage link here"
                    label="Press Coverage Link"
                    :rules="[
                      (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                    ]"
                  >
                  </v-text-field>
                  <v-icon
                    icon="mdi-close"
                    color="red"
                    @click="removePCLink(index)"
                    v-show="index != 0"
                    aria-label="Remove Press Coverage Link"
                  ></v-icon>
                </div>
                <v-btn
                  flat
                  border
                  elevation="0"
                  color="green"
                  variant="outlined"
                  prepend-icon="mdi-plus"
                  @click="addPCLink"
                  aria-label="Add Press Link Button"
                  >Add Press Link</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  data-cy="twitter"
                  v-model="twitter"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  :rules="[
                    (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                  ]"
                  label="Twitter"
                  aria-label="Please enter the Twitter link for the memorial. Leave blank if not applicable."
                  role="textbox"
                  aria-describedby="twitterDescription"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  data-cy="instagram"
                  v-model="instagram"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  :rules="[
                    (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                  ]"
                  label="Instagram"
                  aria-label="Please enter the Instagram link for the memorial. Leave blank if not applicable."
                  aria-describedby="instagramDescription"
                  role="textbox"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  data-cy="facebook"
                  v-model="facebook"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="sub_memorial_css"
                  :rules="[
                    (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                  ]"
                  label="Facebook"
                  aria-label="Please enter the Facebook link for the memorial. Leave blank if not applicable."
                  aria-describedby="facebookDescription"
                  role="textbox"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-card class="overflow-y-auto">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap sub_memorial_css"
                        style="text-align: left"
                        aria-describedby="confirmationStatementDescription"
                        aria-label="I confirm that the information that I am submitting is
                        correct to the best of my knowledge. Please check the below box to continue"
                        aria-checked="false"
                        aria-required="true"
                      >
                        I confirm that the information that I am submitting is
                        correct to the best of my knowledge*</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        data-cy="data_accuracy_checkbox"
                        v-model="firstcheckbox"
                        color="green"
                        class="sub_memorial_css"
                        :rules="[(v) => !!v || 'You must agree to continue!']"
                        label="Yes, I agree"
                        required
                      >
                      </v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="overflow-y-auto">
                  <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title
                        class="text-wrap sub_memorial_css"
                        style="text-align: left"
                      >
                          {{acknowledgementValue}}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        data-cy="acknowledge_checkbox"
                        v-model="seccheckbox"
                        color="green"
                        class="sub_memorial_css"
                        label="Yes, I agree"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
                </v-col>
            </v-row>
            <v-row dense>
              <v-col class="flex-center">
                <v-card class="overflow-y-auto">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap sub_memorial_css"
                        style="text-align: left"
                        data-cy="collab_text"
                        aria-label="Would you like to collaborate with Marked By Covid, the creator of the Memorial Matrix? If yes, please check the below checkbox. If not skip to next part."
                        role="checkbox"
                      >
                        Would you like to collaborate with Marked By Covid, the creator of the Memorial Matrix?
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        data-cy="collab_checkbox"
                        v-model="collaborationAgreement"
                        color="green"
                        class="sub_memorial_css"
                        label="Yes, I agree"
                        required
                      >
                      </v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>

            <v-btn
              :disabled="!isUpdateEnabled"
              data-cy="review"
              class="purple darken-2 white--text mt-5"
              color="green"
              @click="submitForm"
              aria-label="Review Button"
            >
              <h3
                style="
                  text-align: center;
                  font-family: 'Archivo Narrow', sans-serif;
                "
              >
                Review
              </h3>
            </v-btn>
            <p v-if="!isUpdateEnabled"
              class="text-wrap sub_memorial_css alert-class" data-cy="required_fields" 
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              aria-label="Mandatory Fields Missing, Please complete required fields and submit."
              >
              Please complete all required fields to enable the review*
            </p>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { APIService } from "../http/APIService";
import LatLongPicker from "@/components/LatLongPicker.vue";
import PageTitle from "@/components/PageTitle.vue";
const apiService = new APIService();
export default {
  name: "SubmitMemorialView",
  components: {
    PageTitle,
    LatLongPicker,
  },
  data: (vm) => ({
    firstname: "",
    lastname: "",
    youremail: "",
    yzip: "",
    loss: false,
    observance: false,
    affiliated: false,
    counter: 0,
    memorialname: "",
    foundername: "",
    keywords: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    description: "",
    mem_type: "",
    memorialphoto: "",
    urlstring: "",
    memorialwebsite: "",
    memorialemail: "",
    virtualtour: "",
    photolinks: "",
    video: "",
    press: "",
    twitter: "",
    instagram: "",
    facebook: "",
    firstcheckbox: false,
    seccheckbox: false,
    collaborationAgreement: false,
    vcheckbox: false,
    tempcheckbox: false,
    dialog: false,
    latitude: null,
    longitude: null,
    date: null,
    photoLinks: [{ purl: "" }],
    videoLinks: [{ vurl: "" }],
    pressLinks: [{ pcurl: "" }],
    acknowledgementValue: "",

    enddate: null,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 50) || "Name must be less than 50 characters",
    ],
    zipRules: [
      (v) => !!v || "Zip is required",
      (v) => /^\d{5}$/.test(v) || "Zip must be 5 digits",
    ],
    memorialnameRules: [
      (v) => !!v || "Memorial name is required",
      (v) => (v && v.length <= 256) || "Name must be less than 256 characters",
    ],
    founderRulesNot: [
      (v) =>
        !v.trim() ||
        (v && v.length <= 75) ||
        "Name must be less than 75 characters",
    ],
    keywordsRulesNot: [
      (v) =>
        !v.trim() ||
        (v && v.length <= 75) ||
        "Name must be less than 75 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],
    emailRulesNot: [
      (v) =>
        !v ||
        !v.trim() ||
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) ||
        "E-mail must be valid",
    ],
    dateRulesNot: [
      // (0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$  --the strict regexp for mm/dd/yyyy date
      // (v) => ( /^\d{1,2}\/\d{1,2}\/\d{4}$ --just digit,digit/digit,digit/digit,digit,digit,digit
      (v) =>
        !v ||
        !v.trim() ||
        /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(v) ||
        "MM/DD/YYYY format required",
    ],
    descriptionRules: [
      (v) => !!v || "Description is required",
      (v) =>
        (v && v.length <= 4096) ||
        "Description must be less than 4096 characters",
    ],
    keywordsRules: [
      (v) =>
        (v && v.length > 1) ||
        "Keywords should be greater 1 characters",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "should not allow special characters"
    ],

    //
    //Need to get this fixed--using inline rule for now: tried identical format as inline
    //urlRulesNot: [
    //    (v) => (!v.trim()) ||
    //    this.isURL(v)) || "URL is not valid",
    //],

    urlRules: [
      (v) => !!v || "Required.",
      (v) => this.isURL(v) || "URL is not valid",
    ],

    id: 1,
    id1: 1,
    id2: 1,
  }),
  computed: {
    isUpdateEnabled() {
      return (
        !!this.firstname &&
        !!this.lastname &&
        !!this.youremail &&
        !!this.yzip &&
        !!this.memorialname &&
        this.firstcheckbox
      );
    },
  },

  mounted() {
    const formData = JSON.parse(window.localStorage.getItem("formData"));
    const mediaData = JSON.parse(window.localStorage.getItem("mediaData"));
    this.getAcknowledgementValue();
    const isRedirectedFromReview = JSON.parse(
      window.localStorage.getItem("isRedirectedFromReview")
    );
    if (isRedirectedFromReview) {
      if (formData) {
        this.firstname = formData.mem_submitter.first_name;
        this.lastname = formData.mem_submitter.last_name;
        this.youremail = formData.mem_submitter.email;
        this.yzip = formData.mem_submitter.zipcode;
        this.loss = formData.mem_submitter.web_user_loss;
        this.affiliated = formData.mem_submitter.web_user_affiliated;
        this.seccheckbox = formData.mem_submitter.email_updates;
        this.collaborationAgreement = formData.mem_submitter.collaboration_agreement;
        this.memorialname = formData.name;
        this.description = formData.description;
        this.foundername = formData.founder_name;
        this.keywords=formData.keywords;
        this.memorialwebsite = formData.website;
        this.memorialphoto = formData.profile_picture;
        this.virtualtour = formData.google_virtual_tour;
        this.memorialemail = formData.email;
        this.observance = formData.memorial_day_observance;
        this.mem_type = formData.memorial_type_desc;
        this.facebook = formData.social_media_facebook;
        this.instagram = formData.social_media_instagram;
        this.twitter = formData.social_media_twitter;
        this.firstcheckbox = formData.confirm_data_accuracy;
        this.vcheckbox = formData.type == "virtual" ? true : false;
        this.tempcheckbox =
          formData.mem_location.permanent == "temporary" ? true : false;
        let month,
          day,
          year = "";
        formData.mem_location.time_active_start !== null
          ? ([year, month, day] =
              formData.mem_location.time_active_start.split("-"))
          : console.log("start date empty");
        if (
          formData.mem_location.time_active_start !== null &&
          formData.mem_location.time_active_start !== ""
        ) {
          this.date = `${month.padStart(2, "0")}/${day.padStart(
            2,
            "0"
          )}/${year}`;
          console.log(`Reformatted start date is ${this.date}`);
        } else {
          console.log("start date still empty :-/");
        }
        formData.mem_location.time_active_end !== null
          ? ([year, month, day] =
              formData.mem_location.time_active_end.split("-"))
          : console.log("end date empty");
        if (
          formData.mem_location.time_active_end !== null &&
          formData.mem_location.time_active_end !== ""
        ) {
          this.enddate = `${month.padStart(2, "0")}/${day.padStart(
            2,
            "0"
          )}/${year}`;
          console.log(`Reformatted end date is ${this.enddate}`);
        } else {
          console.log("end date still empty :-/");
        }
        this.street = formData.mem_location.address;
        this.state = formData.mem_location.state;
        this.city = formData.mem_location.city;
        this.zip = formData.mem_location.zipcode;
        this.latitude = formData.mem_location.lat_coord;
        this.longitude = formData.mem_location.long_coord;
      }
      if (mediaData) {
        this.photoLinks = mediaData.photoLinks;
        this.videoLinks = mediaData.videoLinks;
        this.pressLinks = mediaData.pressLinks;
      }
      window.localStorage.removeItem("isRedirectedFromReview");
    }
  },
  methods: {

    setPlace(e) {
      console.log(e);
      this.latitude = e.geometry.location.lat();
      this.longitude = e.geometry.location.lng();
      for (const component of e.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case "street_number": {
            this.street = `${component.long_name}`;
            break;
          }

          case "route": {
            this.street += " " + component.short_name;
            break;
          }
          case "postal_code": {
            this.zip = `${component.long_name}`;
            break;
          }
          case "locality":
            this.city = component.long_name;
            break;
          case "administrative_area_level_1": {
            this.state = component.short_name;
            break;
          }
          case "country":
            this.country = component.long_name;
            break;
        }
      }
    },
    handleMapClickLatLong({ lat, lng }) {
      this.latitude = lat;
      this.longitude = lng;
    },
    handleFetchAddress(coords) {
      const API_KEY = process.env.VUE_APP_GOOGLE_API_KEY || ''
      const url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + coords.lat + "," + coords.lng + "&key=" + API_KEY;

      axios.get(url)
        .then(response => {
          const results = response.data.results;
          console.log(results)
          this.street = '';
          this.city = '';
          this.state = '';
          this.zip = '';
          this.country = '';
          if (results.length > 0) {
            const addressComponents = results[0].address_components;
            let tempStreet = '';
            for (const component of addressComponents) {
              const componentType = component.types[0];
              switch (componentType) {
                case "street_number": {
                  tempStreet = `${component.long_name}`;
                  break;
                }
                case "route": {
                  tempStreet += tempStreet ? ` ${component.long_name}` : component.long_name;
                  break;
                }
                case "postal_code": {
                  this.zip = `${component.long_name}`;
                  break;
                }
                case "locality": {
                  this.city = component.long_name;
                  break;
                }
                case "administrative_area_level_1": {
                  this.state = component.short_name;
                  break;
                }
                case "country":
                  this.country = component.long_name;
                  break;
              }
            }
            this.street = tempStreet;
          }
        })
        .catch(error => {
          console.error("Error during reverse geocoding:", error);
        });
     },
    clearAddress() {
      this.street = "";
      this.city = "";
      this.state = "";
      this.zip = "";
      this.latitude = null;
      this.longitude = null;
    },
    clearCheckAndForm() {
      this.loss = false;
      this.observance = false;
      this.affiliated = false;
      this.firstcheckbox = false;
      this.seccheckbox = false;
      this.collaborationAgreement = "";
      this.vcheckbox = false;
      this.tempcheckbox = false;
      this.date = "";
      this.enddate = "";
    },

    removePhotoLink(index) {
      this.photoLinks.splice(index, 1);
    },

    removeVideoLink(index) {
      this.videoLinks.splice(index, 1);
    },

    removePCLink(index) {
      this.pressLinks.splice(index, 1);
    },

    addRow() {
      this.photoLinks.push({
        purl: "",
      });
    },

    addvLink() {
      this.videoLinks.push({
        vurl: "",
      });
    },

    addPCLink() {
      this.pressLinks.push({
        pcurl: "",
      });
    },
    isURL(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },

    getAcknowledgementValue() {
      apiService
        .getWebsiteConfigurationParameter("ACKNOWLEDGEMENT_CHECKBOX_CONFIGURATION")
        .then((res) => {
          this.acknowledgementValue = res.data.data;
          })
    },

    async submitForm() {
      this.$refs.form.validate();
      // There has to be a check about null on save
      // Null error being returned when these are clearable
      // in the form fields and left null
      if (this.street === null) {
        this.street = "";
      }
      if (this.city === null) {
        this.city = "";
      }
      if (this.state === null) {
        this.state = "";
      }
      if (this.zip === null) {
        this.zip = "";
      }
      let month,
        day,
        year = "";
      let formStartStrDate = null;
      let formEndStrDate = null;
      this.date !== null
        ? ([month, day, year] = this.date.split("/"))
        : console.log("start date empty");
      if (this.date !== null && this.date !== "") {
        formStartStrDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
          2,
          "0"
        )}`;
        // this.date = formStrDate;
      } else {
        console.log("start date still empty :-/");
      }
      this.enddate !== null
        ? ([month, day, year] = this.enddate.split("/"))
        : console.log("end date empty");
      if (this.enddate !== null && this.enddate !== "") {
        // let month, day, year = "";
        // [month, day, year] = this.enddate.split("/")
        formEndStrDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
          2,
          "0"
        )}`;
        // this.enddate = formStrDate;
      } else {
        console.log("end date still empty :-/");
      }

      let vtype = !this.vcheckbox ? "physical" : "virtual";

      let locationPerm = !this.tempcheckbox ? "permanent" : "temporary";
      window.localStorage.setItem(
        "formData",
        JSON.stringify({
          mem_submitter: {
            first_name: this.firstname,
            last_name: this.lastname,
            email: this.youremail,
            zipcode: this.yzip,
            web_user_loss: this.loss,
            web_user_affiliated: this.affiliated,
            email_updates: this.seccheckbox,
            collaboration_agreement: this.collaborationAgreement
          },
          name: this.memorialname,
          memorial_day_observance: this.observance,
          description: this.description,
          founder_name: this.foundername,
          keywords: this.keywords,
          website: this.memorialwebsite,
          profile_picture: this.memorialphoto,
          google_virtual_tour: this.virtualtour,
          email: this.memorialemail,
          type: vtype,
          memorial_type_desc: this.mem_type,
          social_media_facebook: this.facebook,
          social_media_instagram: this.instagram,
          social_media_twitter: this.twitter,
          confirm_data_accuracy: this.firstcheckbox,

          mem_location: {
            address: this.street,
            city: this.city,
            state: this.state,
            zipcode: this.zip,
            permanent: locationPerm,
            time_active_start: formStartStrDate,
            time_active_end: formEndStrDate,
            lat_coord: this.latitude,
            long_coord: this.longitude,
          },
        })
      );

      window.localStorage.setItem(
        "mediaData",
        JSON.stringify({
          photoLinks: this.photoLinks,
          videoLinks: this.videoLinks,
          pressLinks: this.pressLinks,
        })
      );
      this.$refs.form.validate().then((result) => {
        if (result.valid) {
          this.$router.push({ name: "reviewmemorial" });
        }
      });
    },
  },
};
</script>
<style>
#app {
  /*font-family: "Avenir", Helvetica, Arial, sans-serif;*/
  font-family: "Archivo Narrow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.sub_memorial_css {
  font-family: "Archivo Narrow", sans-serif;
}
.sub_memorial_css2 {
  font-family: "Archivo Black", sans-serif;
}
.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.alert-class{
  color: #B00120;
  font-size: 0.9em;
}
</style>
