<template>
  <v-sheet :class="isUpcoming ? 'bg-grey-lighten-3' : ''">
    <v-slide-group
      v-model="activeMemorial"
      selectedClass="bg-grey-accent-1"
      center-active
      show-arrows
      :direction="$vuetify.display.xs ? 'vertical' : 'horizontal'"
      role="listbox"
    >
      <v-slide-group-item
        v-for="memorial in memorialSortedByDate"
        :key="memorial.id"
        v-slot="{ isSelected, toggle, selectedClass }"
        role="option"
        :aria-selected="isSelected"
        aria-labelledby="card-title-{{ memorial.id }}"
      >
        <v-card
          :class="['ma-4', selectedClass]"
          elevation="5"
          height="520"
          width="330"
          @click="toggle"
          aria-label="Memorial Card"
        >
          <v-carousel
            height="220"
            show-arrows="hover"
            cycle
            cyle-interval="4000"
            hide-delimiter-background
            :hide-delimiters="false"
            role="group"
            aria-label="Memorial Photos"
          >
            <v-carousel-item
              v-for="photoUrl in getPhotoUrls(memorial.id)"
              :src="photoUrl"
              role="img"
              alt="memorial photo"
              :aria-label="'Photo of ' + memorial.name"
            >
            </v-carousel-item>
          </v-carousel>

          <v-card-title
            :aria-label="'Memorial name is ' + memorial.name"
            class="text-orange-darken-4 text-capitalize font-weight-bold"
          >
            {{ memorial.name }}
          </v-card-title>
          <v-card-subtitle>
            <v-row class="text-green-darken-4 text-capitalize font-weight-bold">
              <v-col :aria-label="'Memorial founder name is ' + memorial.founder_name">{{ memorial.founder_name }}</v-col>
            </v-row>
            <v-row col="12" class="text-blue-darken-4 font-weight-bold">
              <v-col col="6" class="text-left">{{
                memorial.mem_location.time_active_start
              }}</v-col>
              <v-col
                col="6"
                class="text-right"
                v-if="
                  memorial.mem_location != null &&
                  memorial.mem_location.city != null
                "
                >{{ memorial.mem_location.city }},
                {{ memorial.mem_location.state }}</v-col
              >
            </v-row>
          </v-card-subtitle>
          <v-card-text class="text-justify">
            <div v-if="memorial.description.length < 220" :aria-label="'Memorial description is ' + memorial.description">
              {{ memorial.description }}
            </div>
            <div v-else :aria-label="'Memorial description is ' + memorial.description">
              {{ memorial.description.substring(0, 220) + "..." }}
            </div>
          </v-card-text>
          <v-card-actions
            class="position-absolute justify-center"
            style="bottom: 0; left: 100px"
          >
            <v-btn
              @click="toMemorialDetail(memorial.id)"
              class="text-blue justify-center"
              :target="targetValue"
              data-cy="LEARN MORE"
              role="button"
              aria-label="Learn more about this memorial"
            >
              Learn More
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-slide-group-item>
    </v-slide-group>
  </v-sheet>
  <v-row v-if="memorialSortedByDate.length == 0">
    <v-col class="text-center font-weight-bold text-orange-darken-3">
      There are no
      <slot v-if="this.isUpcoming">upcoming events.</slot>
      <slot v-else>featured memorials.</slot>
    </v-col>
  </v-row>
</template>
<script>
import { APIService } from "@/http/APIService";
const apiService = new APIService();

export default {
  name: "Memorial Slide Group",
  props: ["isUpcoming"],
  data: () => ({
    model: null,
    memorials: [],
    photoLinks: new Map(),
    activeMemorial: "",
    learnMoreConfig: null,
    targetValue: ""

  }),
  mounted() {
    this.getMemorialsList();
    this.getLearnMoreConfig();
    this.getMemorialDefaultPhotoUrl();
  },
  computed: {
    memorialSortedByDate() {
      if (this.memorials.length === 0) return this.memorials;

      return this.memorials
        .slice()
        .sort(
          (a, b) =>
            new Date(a.mem_location.time_active_start).setHours(0, 0, 0, 0) -
            new Date(b.mem_location.time_active_start).setHours(0, 0, 0, 0)
        );
    },
    photoUrlsLength(photoUrls) {
      return photoUrls.length;
    },
  },
  methods: {
    getMemorialsList() {
      (this.isUpcoming
        ? apiService.getUpcomingEvents()
        : apiService.getFeaturedMemorials()
      )
        .then((getMemorialsResponse) => {
          this.memorials = getMemorialsResponse.data.data;
          return getMemorialsResponse.data.data;
        })
        .then((getMemorialsResponse) => {
          for (const i in this.memorials) {
            apiService
              .getMemorialProfilePhotoUrl(this.memorials[i].id)
              .then((memorialProfilePhotoLinkUrlResponse) => {
                let photoUrls = new Array();
                photoUrls = memorialProfilePhotoLinkUrlResponse.data.data;
                this.photoLinks.set(this.memorials[i].id, photoUrls);
              });
          }
        });
    },
    prepareUrl(url) {
      if (url != null && !url.startsWith("http")) {
        return "https://" + url;
      }
      return url;
    },
    toMemorialDetail(memorialId) {
        this.$router.push({ name: 'memorialdetail', params: { id: memorialId } });
      },
    getMemorialDefaultPhotoUrl() {
        apiService
          .getWebsiteConfigurationParameter("MEMORIAL_DEFAULT_PICTURE_URL")
          .then((res) => {
            this.defaultMemorialPhotoUrl = res.data.data;
          });
      },
    getPhotoUrls(memorialId) {
      const photoUrls = this.photoLinks.get(memorialId);
      return photoUrls && photoUrls.length ? photoUrls : [this.defaultMemorialPhotoUrl];
    },
    getLearnMoreConfig() {
      apiService
        .getWebsiteConfigurationParameter("FEATURED_MEMORIAL_CONFIGURATION")
        .then((res) => {
          const stringValue = res.data.data;
          const booleanValue = stringValue === 'true';
          this.learnMoreConfig = booleanValue;
          if (this.learnMoreConfig === true) {
            this.targetValue = "_blank";
          }
          else if (this.learnMoreConfig === false) {
            this.targetValue = "_self";
          }
          else {
            this.targetValue = "_self";
          }
        });
    },
  },
};
</script>