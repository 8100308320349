<template>
  <v-item-group selected-class="bg-primary" style="position: relative; top: -85px" role="listbox">
    <v-container>
      <v-row>
        <v-col cols="12" md="3" v-for="(cards, index) in containerItemsList" :key="index" role="listitem" aria-label="card with button">
          <v-item v-if="!(cards.value.display!==true)">
            <v-card :height="cards.value.height" :color="cards.value.color" class="d-flex flex-column">
              <div class="d-flex flex-column fill-height justify-center align-center">
              <div v-if="cards.value.title">
                <p class="title-container text-h4 font-weight-thin text-black text-center" :aria-label="'card title is ' + cards.value.title">
                  {{ cards.value.title }}
                </p>
                <br/>
              </div>
              <div v-if="cards.value.description">
                <p class="description-container text-subtitle-1 font-weight-light text-black text-center" :aria-label="'card description is' + cards.value.description">
                  {{ cards.value.description }}
                </p>
                <br/>
              </div>
                <v-btn
                  :data-cy="cards.value.testingTag"
                  flat
                  size="large"
                  color="white"
                  class="text-capitalize"
                  :href="cards.value.url"
                  :target="cards.value.target"
                  role="button"
                  :aria-label="'Click this button to' +cards.value.buttonText"
                >
                  {{ cards.value.buttonText }}
                </v-btn>
              </div>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>


<script>
import { APIService } from "@/http/APIService";
const apiService = new APIService();
export default {
  name: "FeaturedContainer",
  props: ["memorialCount"],
  data() {
    return {
      containerItemsList: [],
    };
  },
  mounted() {
    this.getCards();
  },
  created() {
    this.getCards();
  },
  methods: {
    async getCards() {
      apiService
        .getContainerCards()
        .then((response) => {
          this.containerItemsList = response.data.items[0].cards;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
}
</script>

<style scoped>
.title-container {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description-container {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>