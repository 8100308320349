<template>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap"
    rel="stylesheet"
  />
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo+Narrow&display=swap"
    rel="stylesheet"
  />

  <PageTitle pageTitle="Update Memorial" page />

  <div id="ModifyMemorial" role="form" aria-labelledby="Update Memorial">
    <v-container fluid>
      <v-row justify="center" class="row bg-grey-lighten-3 text-md-center">
        <v-col cols="auto">
          <v-card elevation="5">
            <v-card-title class="text-wrap mod_memorial_css2" aria-labelledby="Please update your Information">
              Please provide your information:
            </v-card-title>
          </v-card>
          <br />
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="mod_first_name"
                  aria-label="First name"
                  role="textbox"
                  aria-required="true"
                  aria-describedby="Please enter your first name.It should be less than 50 characters"
                  v-model="firstname"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  :rules="nameRules"
                  label="First Name*"
                  counter="50"
                  clearable
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="mod_last_name"
                  aria-label="Last Name"
                  role="textbox"
                  aria-required="true"
                  aria-describedby="Please enter your last name.It should be less than 50 characters"
                  v-model="lastname"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  :rules="nameRules"
                  label="Last Name*"
                  :counter="50"
                  clearable
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="mod_youremail"
                  aria-label="your email"
                  role="textbox"
                  aria-required="true"
                  aria-describedby="Please enter your email address"
                  v-model="youremail"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  :rules="emailRules"
                  label="Email*"
                  clearable
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="mod_yzip"
                  aria-label="Zipcode"
                  role="textbox"
                  aria-required="true"
                  aria-describedby="Please enter your zipcode"
                  v-model="yzip"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  label="Zip*"
                  :rules="zipRules"
                  counter="5"
                  clearable
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" :md="6" :style="{ 'font-family': 'Archivo Narrow, sans-serif' }">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-action
                    aria-label="Did you lose anyone close to you due to Covid-19? If yes please check the box, if not leave it unchecked."
                    aria-checked="false"
                    role="checkbox"
                    >
                      <v-checkbox
                        data-cy="covid_checkbox"
                        v-model="loss"
                        color="green"
                        label="Did you lose anyone close to you due to Covid-19?"
                      ></v-checkbox>
                      </v-list-item-action>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col :cols="12" :md="6" :style="{ 'font-family': 'Archivo Narrow, sans-serif' }">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-checkbox
                        data-cy="covid_aff_checkbox"
                        aria-label="Teja is the developer here"
                        v-model="affiliated"
                        color="green"
                        label="Are you affiliated with Marked By Covid?"
                        role="checkbox"
                        aria-labelledby="Are you affiliated with Marked By Covid? If yes please check the box, if not leave it unchecked"
                        aria-checked="false"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card elevation="5">
                  <v-card-title id="memorialDetailsLabel" class="text-wrap mod_memorial_css2" aria-labelledby="Please modify the memorial details below"
                    >Memorial Details:</v-card-title
                  >
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap mod_memorial_css"
                        style="text-align: left"
                        aria-describedby="If you do not know the official name of the memorial,
                        please put a brief description (eg. “I-95 Roadside
                        Memorial”)"
                      >
                        If you do not know the official name of the memorial,
                        please put a brief description (eg. “I-95 Roadside
                        Memorial”)*
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-textarea
                          v-model="memorialname"
                          :rules="memorialnameRules"
                          aria-describedby="Update the name of the memorial"
                          aria-label="please modify memorial name"
                          aria-required="true"
                          auto-grow
                          bg-color="grey-lighten-4"
                          class="mod_memorial_css"
                          clearable
                          counter="256"
                          data-cy="mod_memorial_name"
                          label="Memorial Name*"
                          required
                          role="textbox"
                          variant="solo"
                      ></v-textarea>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>

              <v-col cols="12" md="3">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap mod_memorial_css"
                        style="text-align: left"
                        aria-describedby="Please modify the description of the memorial.(This may include an artist's statement or rationale)"
                        aria-label="Please modify the description of the memorial.(This may include an artist's statement or rationale)"
                      >
                        Please provide a description of the memorial. (This may
                        include an artist’s statement or rationale.)*
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-textarea
                        data-cy="memorial_description"
                        aria-label="please modify memorial Description"
                        role="textbox"
                        aria-describedby="Modify Description of the memorial"
                        auto-grow
                        clearable
                        v-model="description"
                        variant="solo"
                        bg-color="grey-lighten-4"
                        class="mod_memorial_css"
                        type="description"
                        :rules="descriptionRules"
                        counter="4096"
                        label="Memorial Description*"
                        required
                      ></v-textarea>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap mod_memorial_css"
                        style="text-align: left"
                        aria-label="Founder's Name"
                      >
                        Please provide the name of the founder for this memorial
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-textarea
                        data-cy="mod_founder_name"
                        aria-label="please modify founder name"
                        aria-describedby="Founder Name Description"
                        role="textbox"
                        v-model="foundername"
                        variant="solo"
                        class="mod_memorial_css"
                        bg-color="grey-lighten-4"
                        :rules="founderRulesNot"
                        counter="75"
                        label="Contact/Founder"
                        clearable
                      ></v-textarea>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>

              <v-col cols="12" md="3">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap mod_memorial_css"
                        style="text-align: left"
                        aria-describedby="Keywords"
                      >
                        Please enter your keywords for the memorial here (eg. "#memorialidentifier")
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-textarea
                        data-cy="keywords"
                        aria-label="Please modify your keywords for the memorial here. for example, #memorialidentifier.This information helps with categorization and searching."
                        auto-grow
                        clearable
                        v-model="keywords"
                        variant="solo"
                        bg-color="grey-lighten-4"
                        class="mod_memorial_css"
                        type="keywords"
                        counter = "75"
                        label="Keywords"
                      ></v-textarea>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap, sub_memorial_css"
                        style="text-align: left"
                        >Is this memorial part of a Covid Memorial Day
                        observance?</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        data-cy="covid_obs_checkbox"
                        aria-label="covid memorial day observance checkbox"
                        aria-describedby="observanceDescription"
                        aria-checked="false"
                        role="checkbox"
                        v-model="observance"
                        color="green"
                        label="Yes"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap mod_memorial_css"
                        style="text-align: left"
                        aria-describedby="addressInstructions"
                        >Please use the auto complete address field below or use
                        open map to the enter address</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <GMapAutocomplete
                        data-cy="mod_mem_location"
                        aria-label="memorial location"
                        @place_changed="setPlace"
                      >
                        <!-- <template v-slot:input="slotProps">
                    <v-text-field
                      ref="input"
                      v-bind="slotProps"
                      prepend-inner-icon="mdi-map-marker"
                      hide-details
                      clearable
                      @click:clear="clearAddress()"
                    />
                  </template> -->
                      </GMapAutocomplete>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="street"
                  aria-label="Please modify the street address"
                  v-model="street"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  label="Street Address"
                  clearable
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  data-cy="city"
                  aria-label="Please modify the city"
                  v-model="city"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  label="City"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  data-cy="state"
                  aria-label="Please Modify the state"
                  v-model="state"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  label="State"
                  clearable
                  persistent-hint
                  hint="Two Letter Code"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  data-cy="zip"
                  aria-label="please modify the zipcode"
                  v-model="zip"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  label="Zip"
                  clearable
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md>
                <v-card class="overflow-y-auto">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap mod_memorial_css"
                        style="text-align: left"
                        aria-describedby="If you do not know the street address, use this link to
                        drop a pin"
                        >If you do not know the street address, use this link to
                        drop a pin.</v-list-item-title
                      >
                    </v-list-item-content>
                    <br />
                    <v-list-item-action>
                      <v-btn
                        @click="dialog = true"
                        color="green"
                        variant="outlined"
                        append-icon="mdi-google-maps"
                        aria-label="Open Map Dialog Button"
                        >Open Map
                      </v-btn>
                      <v-dialog v-model="dialog" width="unset" aria-label="Google Maps Dialog">
                        <v-card>
                          <v-card-text>
                            <LatLongPicker
                              :initialLat="latitude"
                              :initialLng="longitude"
                              @mapClickLatLong="handleMapClickLatLong"
                              @fetchAddress="handleFetchAddress"
                            />
                          </v-card-text>
                          <v-card-actions>
                            <v-btn color="primary" block @click="dialog = false"
                              >Please Pin & Click on Marker and Close
                              Dialog</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  data-cy="mod_mem_type"
                  aria-label="Please modify the memorial type"
                  aria-describedby="typeDescription"
                  v-model="mem_type"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  label="Type"
                  hint="Example: website, monument, plaque"
                  persistent-hint
                ></v-text-field>
                <div id="typeDescription" class="sr-only">
                  Please modify the type of memorial. for example, website, monument, Plaque.
                </div>
              </v-col>
              <v-col cols="6" md="6">
                <v-checkbox
                  data-cy="mod_vcheckbox"
                  aria-label="type of memorial virtual"
                  role="checkbox"
                  aria-checked="false"
                  v-model="vcheckbox"
                  color="green"
                  label="Virtual"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" md="6">
                <v-checkbox
                  data-cy="mod_temp_checkbox"
                  aria-label="type of memorial temporary"
                  role="checkbox"
                  aria-checked="false"
                  v-model="tempcheckbox"
                  color="green"
                  label="Temporary"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="date"
                  aria-label="please modify the memorial start date"
                  aria-describedby="dateDescription"
                  v-model="date"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  :rules="dateRulesNot"
                  label="Memorial Start Date"
                  hint="MM/DD/YYYY"
                  persistent-hint
                >
                </v-text-field>
                <div id="dateDescription" class="sr-only">
                  Please modify the start date of the memorial in the format MM/DD/YYYY.
                </div>
                <p style="font-family: 'Archivo Narrow', sans-serif" ARIA-LABEL=" Leave blank if unknown">
                  Leave blank if unknown
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="end_date"
                  aria-label="Please modify the memorial end date"
                  aria-describedby="endDateDescription"
                  v-model="enddate"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  :rules="dateRulesNot"
                  label="Memorial End Date"
                  hint="MM/DD/YYYY"
                  persistent-hint
                ></v-text-field>
                <p style="font-family: 'Archivo Narrow', sans-serif" aria-label="Leave blank if not applicable">
                  Leave blank if not applicable
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="mod_memorial_website"
                  aria-label="memorial website link"
                  aria-describedby="websiteLinkDescription"
                  v-model="memorialwebsite"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  :rules="[
                    (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                  ]"
                  label="Website Link"
                ></v-text-field>
                <div id="websiteLinkDescription" class="sr-only">
                  Please modify the website link for the memorial.Leave blank if not applicable.
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="mod_memorial_email"
                  aria-label="memorial email"
                  aria-describedby="emailDescription"
                  v-model="memorialemail"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  :rules="emailRulesNot"
                  label="Email"
                ></v-text-field>
                <div id="emailDescription" class="sr-only">
                  Please modify the email for the memorial.Leave blank if not applicable.
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="mod_memorial_profile_link"
                  aria-label="Please add or modify memorial profile photo link"
                  aria-describedby="profilePhotoLinkDescription"
                  v-model="memorialphoto"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  :rules="[
                    (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                  ]"
                  label="Profile Photo Link"
                  persistent-hint
                  hint="Add a profile photo link here"
                ></v-text-field>
                <div id="profilePhotoLinkDescription" class="sr-only">
                  Please modify the profile photo link for the memorial.Leave blank if not applicable.
                </div>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  data-cy="mod_virtual_tour"
                  aria-label="virtual tour link"
                  aria-describedby="virtualTourLinkDescription"
                  v-model="virtualtour"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  :rules="[
                    (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                  ]"
                  label="Virtual Tour Link"
                ></v-text-field>
                <div id="virtualTourLinkDescription" class="sr-only">
                  Please modify the website link for the memorial.Leave blank if not applicable.
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <div
                  v-for="(item, index) in photoLinks"
                  :key="index"
                  class="d-flex"
                >
                  <v-text-field
                    data-cy="mod_photo_links"
                    aria-label="please add or  modify the photo link"
                    aria-describedby="profilePhotoLinkDescription"
                    v-model="item.purl"
                    label="Photo Link"
                    persistent-hint
                    hint="Add a photo link here"
                    variant="solo"
                    bg-color="grey-lighten-4"
                    class="mod_memorial_css"
                    :rules="[
                      (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                    ]"
                  >
                  </v-text-field>
                   <div id="profilePhotoLinkDescription" class="sr-only">
                  Please modify the profile photo link for the memorial.Leave blank if not applicable.
                </div>
                  <v-icon
                    icon="mdi-close"
                    color="red"
                    @click="removePhotoLink(index)"
                    aria-label="Remove Photo link"
                    v-show="index != 0"
                  ></v-icon>
                </div>
                <v-btn
                  border
                  elevation="0"
                  color="green"
                  variant="outlined"
                  prepend-icon="mdi-plus"
                  @click="addRow"
                  aria-label="Add photo link"
                  >Add Photo Link</v-btn
                >
              </v-col>

              <v-col cols="12" md="4">
                <div
                  v-for="(item, index) in videoLinks"
                  :key="index"
                  class="d-flex"
                >
                  <v-text-field
                    data-cy="mod_video_links"
                    aria-label="Please  add or modify the video link"
                    aria-describedby="videoLinkDescription"
                    v-model="item.vurl"
                    label="Video Link"
                    persistent-hint
                    hint="Add a video link here"
                    variant="solo"
                    bg-color="grey-lighten-4"
                    class="mod_memorial_css"
                    :rules="[
                      (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                    ]"
                  >
                  </v-text-field>
                  <div id="videoLinkDescription" class="sr-only">
                    Please modify the video link for the memorial.Leave blank if not applicable.
                  </div>
                  <v-icon
                    icon="mdi-close"
                    color="red"
                    @click="removeVideoLink(index)"
                    aria-label="Remove Video Link"
                    v-show="index != 0"
                  ></v-icon>
                </div>
                <v-btn
                  border
                  elevation="0"
                  color="green"
                  variant="outlined"
                  prepend-icon="mdi-plus"
                  @click="addvLink"
                  aria-label="Add Video Link"
                  >Add Video Link</v-btn
                >
              </v-col>

              <v-col cols="12" md="4">
                <div
                  v-for="(item, index) in pressLinks"
                  :key="index"
                  class="d-flex"
                >
                  <v-text-field
                    data-cy="mod_press_links"
                    aria-label="please add or modify press coverage link"
                    aria-describedby="pressLinkDescription"
                    v-model="item.pcurl"
                    label="Press Coverage Link"
                    persistent-hint
                    hint="Add a press coverage link here"
                    variant="solo"
                    bg-color="grey-lighten-4"
                    class="mod_memorial_css"
                    :rules="[
                      (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                    ]"
                  >
                  </v-text-field>
                   <div id="pressLinkDescription" class="sr-only">
                    Please modify the press coverage link for the memorial.Leave blank if not applicable.
                  </div>
                  <v-icon
                    icon="mdi-close"
                    color="red"
                    @click="removePCLink(index)"
                    aria-label="remove Press coverage link"
                    v-show="index != 0"
                  ></v-icon>
                </div>
                <v-btn
                  border
                  elevation="0"
                  color="green"
                  variant="outlined"
                  prepend-icon="mdi-plus"
                  @click="addPCLink"
                  aria-label="Add Press Coverage Link"
                  >Add Press Link</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  data-cy="mod_twitter"
                  aria-label="please add or modify Twitter profile link"
                  aria-describedby="twitterDescription"
                  v-model="twitter"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  :rules="[
                    (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                  ]"
                  label="Twitter"
                ></v-text-field>
                 <div id="twitterDescription" class="sr-only">
                    Please modify the twitter  link for the memorial.Leave blank if not applicable.
                  </div>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  data-cy="mod_instagram"
                  aria-label="please add or modify Instagram profile link"
                  aria-describedby="InstagramDescription"
                  v-model="instagram"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  :rules="[
                    (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                  ]"
                  label="Instagram"
                ></v-text-field>
                 <div id="InstagramDescription" class="sr-only">
                    Please modify the instagram link for the memorial.Leave blank if not applicable.
                  </div>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  data-cy="mod_facebook"
                  aria-label="please add or modify Facebook profile link"
                  aria-describedby="facebookDescription"
                  v-model="facebook"
                  variant="solo"
                  bg-color="grey-lighten-4"
                  class="mod_memorial_css"
                  :rules="[
                    (v) => !v.trim() || this.isURL(v) || 'URL is not valid',
                  ]"
                  label="Facebook"
                ></v-text-field>
                 <div id="facebookDescription" class="sr-only">
                    Please modify the facebook link for the memorial.Leave blank if not applicable.
                  </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-card class="overflow-y-auto">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap mod_memorial_css"
                        style="text-align: left"
                        aria-describedby="confrimationStatementDescription"
                      >
                        I confirm that the information that I am submitting is
                        correct to the best of my knowledge*</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        data-cy="mod_data_accuracy_checkbox"
                        aria-label="You must agree to continue"
                        role="checkbox"
                        aria-labelledby="You must agree to continue"
                        aria-required="false"
                        aria-checked="false"
                        v-model="firstcheckbox"
                        color="green"
                        :rules="[(v) => !!v || 'You must agree to continue!']"
                        label="Yes, I agree"
                        required
                      >
                      </v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="overflow-y-auto">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        data-cy="update-text"
                        class="text-wrap mod_memorial_css"
                        style="text-align: left"
                        >{{ acknowledgementValue }}</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        data-cy="mod_acknowledge_checkbox"
                        v-model="seccheckbox"
                        color="green"
                        label="Yes, I agree"
                        role="checkbox"
                        aria-label="Acknowledge checkbox"
                        aria-labelledby="acknowledgment"
                        aria-checked="false"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="flex-center">
                <v-card class="overflow-y-auto">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-wrap sub_memorial_css"
                        style="text-align: left"
                        data-cy="collab_text"
                        aria-label="Acknowledge checkbox"
                        aria-labelledby="acknowledgment"
                        aria-checked="false"
                        aria-describedby="collaborationTextDescription"
                      >
                        Would you like to collaborate with Marked By Covid, the creator of the Memorial Matrix?
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        data-cy="collab_checkbox"
                        aria-label="collaboration checkbox"
                        aria-labelledby="collaboration"
                        role="checkbox"
                        aria-checked="false"
                        aria-required="true"
                        v-model="collaborationAgreement"
                        color="green"
                        class="sub_memorial_css"
                        label="Yes, I agree"
                        required
                      >
                      </v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>

            <v-btn
              :disabled="!isUpdateEnabled || isModifying"
              data-cy="update"
              class="purple darken-2 white--text mt-5"
              color="blue"
              @click="handleCaptcha"
              aria-label="Update button"
            >
              <h3
                style="
                  text-align: center;
                  font-family: 'Archivo Narrow', sans-serif;
                "
              >
                Update
              </h3>
            </v-btn>
            <p v-if="!isUpdateEnabled"
              class="text-wrap sub_memorial_css alert-class" role="alert" aria-live="assertive" aria-atomic="true">
              Please complete all required fields to enable the update*
            </p>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { APIService } from "../http/APIService";
import LatLongPicker from "@/components/LatLongPicker.vue";
import PageTitle from "@/components/PageTitle.vue";
const apiService = new APIService();
export default {
  name: "ModifyMemorialView",
  components: {
    PageTitle,
    LatLongPicker,
  },
  data: (vm) => ({
    mem_submitter_id: "",
    mem_location_id: "",
    firstname: "",
    lastname: "",
    youremail: "",
    yzip: "",
    loss: false,
    affiliated: false,
    observance: false,
    counter: 0,
    memorialname: "",
    foundername: "",
    keywords:"",
    street: "",
    city: "",
    state: "",
    zip: "",
    description: "",
    mem_type: "",
    memorialphoto: "",
    urlstring: "",
    memorialwebsite: "",
    memorialemail: "",
    virtualtour: "",
    photolinks: "",
    video: "",
    press: "",
    twitter: "",
    instagram: "",
    facebook: "",
    firstcheckbox: false,
    seccheckbox: false,
    collaborationAgreement: false,
    vcheckbox: false,
    tempcheckbox: false,
    dialog: false,
    latitude: null,
    longitude: null,
    date: null,
    photoLinks: [{ purl: "" }],
    videoLinks: [{ vurl: "" }],
    pressLinks: [{ pcurl: "" }],
    enddate: null,
    acknowledgementValue: "",
    isModifying: false,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 50) || "Name must be less than 50 characters",
    ],
    zipRules: [
      (v) => !!v || "Zip is required",
      (v) => /^\d{5}$/.test(v) || "Zip must be 5 digits",
    ],
    memorialnameRules: [
      (v) => !!v || "Memorial name is required",
      (v) => (v && v.length <= 256) || "Name must be less than 256 characters",
    ],
    founderRulesNot: [
      (v) =>
        !v.trim() ||
        (v && v.length <= 75) ||
        "Name must be less than 75 characters",
    ],

    keywordsRulesNot: [
      (v) =>
        !v.trim() ||
        (v && v.length <= 75) ||
        "Name must be less than 75 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],
    emailRulesNot: [
      (v) =>
        !v.trim() ||
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) ||
        "E-mail must be valid",
    ],
    dateRulesNot: [
      // (0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$  --the strict regexp for mm/dd/yyyy date
      // (v) => ( /^\d{1,2}\/\d{1,2}\/\d{4}$ --just digit,digit/digit,digit/digit,digit,digit,digit
      (v) =>
        !v ||
        !v.trim() ||
        /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(v) ||
        "MM/DD/YYYY format required",
    ],
    descriptionRules: [
      (v) => !!v || "Description is required",
      (v) =>
        (v && v.length <= 4096) ||
        "Description must be less than 4096 characters",
    ],
    keywordsRules: [
      (v) =>
        (v && v.length > 1) ||
        "Keywords should be greater 1 characters",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "should not allow special characters"
    ],

    urlRules: [
      (v) => !!v || "Required.",
      (v) => this.isURL(v) || "URL is not valid",
    ],

    id: 1,
    id1: 1,
    id2: 1,
    pFlag: true,
    vFlag: true,
    pcFlag: true,
  }),

  computed: {
    isUpdateEnabled() {
      return (
        !!this.firstname &&
        !!this.lastname &&
        !!this.youremail &&
        !!this.yzip &&
        !!this.memorialname &&
        this.firstcheckbox
      );
    },
  },

  created() {
    this.getData(this.$route.params.id);
    this.getMedia();
    this.getAcknowledgementValue();
  },
  methods: {
    setPlace(e) {
      this.latitude = e.geometry.location.lat();
      this.longitude = e.geometry.location.lng();
      for (const component of e.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case "street_number": {
            this.street = `${component.long_name}`;
            break;
          }
          case "route": {
            this.street += " " + component.short_name;
            break;
          }
          case "postal_code": {
            this.zip = `${component.long_name}`;
            break;
          }
          case "locality":
            this.city = component.long_name;
            break;
          case "administrative_area_level_1": {
            this.state = component.short_name;
            break;
          }
          case "country":
            this.country = component.long_name;
            break;
        }
      }
    },
    handleMapClickLatLong({ lat, lng }) {
      this.latitude = lat;
      this.longitude = lng;
    },
    handleFetchAddress(coords) {
      const API_KEY = process.env.VUE_APP_GOOGLE_API_KEY || ''
      const url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + coords.lat + "," + coords.lng + "&key=" + API_KEY;

      axios.get(url)
        .then(response => {
          const results = response.data.results;
          console.log(results)
          this.street = '';
          this.city = '';
          this.state = '';
          this.zip = '';
          this.country = '';
          if (results.length > 0) {
            const addressComponents = results[0].address_components;
            let tempStreet = '';
            for (const component of addressComponents) {
              const componentType = component.types[0];
              switch (componentType) {
                case "street_number": {
                  tempStreet = `${component.long_name}`;
                  break;
                }
                case "route": {
                  tempStreet += tempStreet ? ` ${component.long_name}` : component.long_name;
                  break;
                }
                case "postal_code": {
                  this.zip = `${component.long_name}`;
                  break;
                }
                case "locality": {
                  this.city = component.long_name;
                  break;
                }
                case "administrative_area_level_1": {
                  this.state = component.short_name;
                  break;
                }
                case "country":
                  this.country = component.long_name;
                  break;
              }
            }
            this.street = tempStreet;
          }
        })
        .catch(error => {
          console.error("Error during reverse geocoding:", error);
        });
     },
    clearAddress() {
      this.street = "";
      this.city = "";
      this.state = "";
      this.zip = "";
      this.latitude = null;
      this.longitude = null;
    },

    clearCheckAndForm() {
      this.loss = false;
      this.affiliated = false;
      this.observance = false;
      this.firstcheckbox = false;
      this.seccheckbox = false;
      this.collaborationAgreement = false;
      this.vcheckbox = false;
      this.tempcheckbox = false;
      this.dialog = false;
      this.date = "";
      this.enddate = "";
      this.menu1 = false;
      this.menu2 = false;
    },

    removePhotoLink(index) {
      this.photoLinks.splice(index, 1);
    },

    removeVideoLink(index) {
      this.videoLinks.splice(index, 1);
    },

    removePCLink(index) {
      this.pressLinks.splice(index, 1);
    },

    addRow() {
      this.photoLinks.push({
        purl: "",
      });
    },

    addvLink() {
      this.videoLinks.push({
        vurl: "",
      });
    },

    addPCLink() {
      this.pressLinks.push({
        pcurl: "",
      });
    },
    isURL(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },

    getAcknowledgementValue() {
      apiService
        .getWebsiteConfigurationParameter("ACKNOWLEDGEMENT_CHECKBOX_CONFIGURATION")
        .then((res) => {
          this.acknowledgementValue = res.data.data;
          })
    },

    async getData(id) {
      let id2 = localStorage.getItem("id");
      apiService.getMemorialView(id2).then((resp) => {
        this.mem_location_id = resp.data.mem_location.id;
        this.memorialname = resp.data.name;
        this.observance = resp.data.memorial_day_observance;
        this.foundername = resp.data.founder_name;
        this.keywords = resp.data.keywords;
        this.description = resp.data.description;
        this.street = resp.data.mem_location.address;
        this.city = resp.data.mem_location.city;
        this.state = resp.data.mem_location.state;
        this.zip = resp.data.mem_location.zipcode;
        this.latitude = resp.data.mem_location.lat_coord;
        this.longitude = resp.data.mem_location.long_coord;
        this.mem_type = resp.data.memorial_type_desc;
        this.vcheckbox = resp.data.type === "virtual";
        this.tempcheckbox = resp.data.mem_location.permanent === "temporary";
        this.date = resp.data.mem_location.time_active_start;
        //Remember, date comes back in YYYY-MM_DD format
        let month,
          day,
          year = "";
        let formStrDate,
          ddmmyyyyStrDate = "";
        this.date !== null
          ? ([year, month, day] = this.date.split("-"))
          : console.log("start date empty");
        if (this.date !== null) {
          formStrDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
            2,
            "0"
          )}`;
          console.log(
            `the date back from db ${this.date} and parsed date ${formStrDate}`
          );
          ddmmyyyyStrDate = `${month.padStart(2, "0")}/${day.padStart(
            2,
            "0"
          )}/${year}`;
          this.date = ddmmyyyyStrDate;
        } else {
          console.log("start date still empty :-/");
        }
        this.enddate = resp.data.mem_location.time_active_end;
        let formEndDate,
          ddmmyyyyEndDate = "";
        this.enddate !== null || this.enddate === ""
          ? ([year, month, day] = this.enddate.split("-"))
          : console.log("end date empty");
        if (this.enddate !== null || this.enddate === "") {
          formEndDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
            2,
            "0"
          )}`;
          ddmmyyyyEndDate = `${month.padStart(2, "0")}/${day.padStart(
            2,
            "0"
          )}/${year}`;
          this.enddate = ddmmyyyyEndDate;
        } else {
          console.log("end date still empty :-/");
        }
        resp.data.website
          ? (this.memorialwebsite = resp.data.website)
          : (this.memorialwebsite = "");
        this.memorialemail = resp.data.email;
        this.memorialphoto = resp.data.profile_picture;
        this.virtualtour = resp.data.google_virtual_tour;
        this.twitter = resp.data.social_media_twitter;
        this.facebook = resp.data.social_media_facebook;
        this.instagram = resp.data.social_media_instagram;
      });
      //fix date
      //Remember, date comes back in YYYY-MM-DD format
      let month,
        day,
        year = "";
      let formStrDate,
        ddmmyyyyStrDate = "";
      this.date !== null
        ? ([year, month, day] = this.date.split("-"))
        : console.log("start date empty");
      if (this.date !== null) {
        formStrDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
          2,
          "0"
        )}`;
        ddmmyyyyStrDate = `${month.padStart(2, "0")}/${day.padStart(
          2,
          "0"
        )}/${year}`;
        this.date = ddmmyyyyStrDate;
      } else {
        console.log("start date still empty :-/");
      }
      this.enddate = this.parseSlashDate;
    },
    async getMedia() {
      let id = localStorage.getItem("id");
      apiService.getMediaLinksView(id).then((resp) => {
        //this.memorialphoto = resp.data.data[0].url
        for (let i = 0; i < resp.data.length; i++) {
          if (resp.data[i].type == "photo") {
            if (this.pFlag) {
              this.photoLinks[0].purl = resp.data[i].url;
              this.pFlag = false;
              continue;
            }
            this.photoLinks.push({
              purl: resp.data[i].url,
            });
          }
          if (resp.data[i].type == "video") {
            if (this.vFlag) {
              this.videoLinks[0].vurl = resp.data[i].url;
              this.vFlag = false;
              continue;
            }
            this.videoLinks.push({
              vurl: resp.data[i].url,
            });
          }
          if (resp.data[i].type == "press coverage") {
            if (this.pcFlag) {
              this.pressLinks[0].pcurl = resp.data[i].url;
              this.pcFlag = false;
              continue;
            }
            this.pressLinks.push({
              pcurl: resp.data[i].url,
            });
          }
        }
      });
    },

    async handleCaptcha() {
      try {
        const token = await this.$recaptcha('updateMemorial');
        if (token) {
          const isCaptchaValid = await this.verifyCaptchaToken(token);
          if (isCaptchaValid) {
            await this.modifyForm();
          } else {
            console.error('Invalid reCAPTCHA token');
          }
        }
      } catch (error) {
        console.error('reCAPTCHA error:', error);
      }
    },
    async verifyCaptchaToken(token) {
      const API_URL = process.env.VUE_APP_API_ENDPOINT;
      const verifyUrl = `${API_URL}api/verify_recaptcha/`;

      try {
        axios.defaults.xsrfCookieName = "csrftoken";
        axios.defaults.xsrfHeaderName = "X-CSRFToken";
        const response = await axios.post(verifyUrl, { token });
        return response.data.success;
      } catch (error) {
        console.error('Error verifying reCAPTCHA:', error);
        alert("Oops! We couldn't verify your reCAPTCHA score. Please try submitting the form again. If the problem persists, check your internet connection or try reloading the page.");
        return false;
      }
    },

    async modifyForm() {
      this.isModifying = true;
      this.$refs.form.validate();
      // There has to be a check about null on save
      // Null error being returned when these are clearable
      // in the form fields and left null
      if (this.street === null) {
        this.street = "";
      }
      if (this.city === null) {
        this.city = "";
      }
      if (this.state === null) {
        this.state = "";
      }
      if (this.zip === null) {
        this.zip = "";
      }
      let id2 = localStorage.getItem("id");
      let month,
        day,
        year = "";
      let formStrDate = "";
      this.date !== null
        ? ([month, day, year] = this.date.split("/"))
        : console.log("start date empty");
      if (this.date !== null) {
        formStrDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
          2,
          "0"
        )}`;
        this.date = formStrDate;
      } else {
        console.log("start date still empty :-/");
      }

      this.enddate !== null
        ? ([month, day, year] = this.enddate.split("/"))
        : console.log("end date empty");
      if (this.enddate !== null) {
        // let month, day, year = "";
        // [month, day, year] = this.enddate.split("/")
        formStrDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
          2,
          "0"
        )}`;
        this.enddate = formStrDate;
      } else {
        console.log("end date still empty :-/");
      }
      let vtype = !this.vcheckbox ? "physical" : "virtual";
      let locationPerm = !this.tempcheckbox ? "permanent" : "temporary";
      let memorial_id;
      const API_URL = process.env.VUE_APP_API_ENDPOINT;
      let url = API_URL + "api/update/" + id2 + "/";
      axios.defaults.xsrfCookieName = "csrftoken";
      axios.defaults.xsrfHeaderName = "X-CSRFToken";
      await axios
        .put(url, {
          mem_submitter: {
            first_name: this.firstname,
            last_name: this.lastname,
            email: this.youremail,
            zipcode: this.yzip,
            web_user_loss: this.loss,
            web_user_affiliated: this.affiliated,
            email_updates: this.seccheckbox,
            collaborationAgreement: this.collaborationAgreement
          },
          id: id2,
          name: this.memorialname,
          memorial_day_observance: this.observance,
          description: this.description,
          founder_name: this.foundername,
          keywords: this.keywords,
          website: this.memorialwebsite,
          profile_picture: this.memorialphoto,
          google_virtual_tour: this.virtualtour,
          email: this.memorialemail,
          type: vtype,
          memorial_type_desc: this.mem_type,
          social_media_facebook: this.facebook,
          social_media_instagram: this.instagram,
          social_media_twitter: this.twitter,
          confirm_data_accuracy: this.firstcheckbox,

          mem_location: {
            address: this.street,
            city: this.city,
            state: this.state,
            zipcode: this.zip,
            permanent: locationPerm,
            time_active_start: this.date,
            time_active_end: this.enddate,
            lat_coord: this.latitude,
            long_coord: this.longitude,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          this.clearCheckAndForm();
        });
      let mediaurl = API_URL + "api/medialinkUpdate/";
      await axios
        .put(mediaurl, {
          photoLinks: this.photoLinks,
          videoLinks: this.videoLinks,
          pressLinks: this.pressLinks,
          memorial: id2,
        })
        .then((response) => {
          this.$refs.form.reset();
          this.$router.push({ name: "memorialsaved" });
        })
        .catch((error) => {
          this.clearCheckAndForm();
        });
    },
    parseDashDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseSlashDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style>
.mod_memorial_css {
  font-family: "Archivo Narrow", sans-serif;
}
.mod_memorial_css2 {
  font-family: "Archivo Black", sans-serif;
}
.alert-class{
  color: #B00120;
  font-size: 0.9em;
}
</style>
