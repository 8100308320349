<template>
  <v-parallax
    height="800"
    :src="
      homePagePhotoUrl != null
        ? homePagePhotoUrl
        : require('@/assets/memorial.jpg')
    "
    style="position: relative; top: -16px"
    aria-label="Decorative background image"
    role="img"
    alt="background image"
  >
    <div
      class="d-flex flex-column fill-height justify-center align-center text-white"
    >
      <p class="text-h4 text-center" aria-label="Website Purpose">{{ splitDescriptionTitle[0] }}<br/>{{ splitDescriptionTitle[1] }}</p>
      <p class="text-subtitle-1 font-weight-thin text-center" aria-label="What includes the website">{{ splitDescriptionSubtitle[0] }}<br/>{{ splitDescriptionSubtitle[1] }}</p>
      <p class="text-subtitle-1 font-weight-light" :aria-label="'Number of memorials:' + memorialCount">
          Featuring <strong data-cy="mem_count">{{ memorialCount }}</strong> Memorials
       </p>
      <br />

      <div>
        <v-btn
          flat
          variant="outlined"
          v-if="watchVideoEmbedUrl"
          size="large"
          prepend-icon="mdi-play"
          class="text-capitalize"
          @click="homeVideDialog = true"
          aria-label="Watch video button"
        >
          Watch Video
          <v-dialog v-model="homeVideDialog" width="auto" aria-label="Video" role="dialog" aria-modal="true">
            <v-card width="640" height="440" class="bg-grey-lighten-2">
              <v-card-item v-html="watchVideoEmbedUrl" />
            </v-card>
          </v-dialog>
        </v-btn>
      </div>
    </div>
  </v-parallax>

  <FeaturedContainers :memorialCount = "memorialCount" aria-label="Cards with buttons" />
  <v-sheet
    class="bg-grey-lighten-3"
    fluid
    style="position: relative; top: -45px"
    aria-label="Memorial map"
  >
    <MemorialMapView />
  </v-sheet>

  <br />

  <v-sheet fluid style="position: relative; top: -45px">
    <div class="d-flex flex-column fill-height justify-center align-center" data-cy="featured memorial">
      <PageTitle pageTitle="Featured Memorials" aria-label="Section title is: Featured memorials" />
    </div>
  </v-sheet>

  <div style="position: relative; top: -45px">
    <MemorialSlideGroup :is-upcoming="false" />
  </div>

  <br />

  <v-sheet
    class="bg-grey-lighten-3"
    fluid
    style="position: relative; top: -45px"
    aria-label="Upcoming events"
  >
    <div class="d-flex flex-column fill-height justify-center align-center">
      <PageTitle pageTitle="Upcoming Events" class="bg-grey-lighten-3" aria-label="Section title is: Upcoming Events" />
    </div>
  </v-sheet>

  <div style="position: relative; top: -45px">
    <MemorialSlideGroup :is-upcoming="true" class="bg-grey-lighten-3" />
  </div>
</template>

<script>
// @ is an alias to /src
import MapComponent from "@/components/MapComponent.vue";
import Slider from "@vueform/slider";
import MemorialSlideGroup from "@/views/MemorialSlideGroup.vue";
import MemorialMapView from "@/views/MemorialMap.vue";
import { APIService } from "@/http/APIService";
import PageTitle from "@/components/PageTitle.vue";
import FeaturedContainers from "@/components/HomeCardsContainer.vue"


const apiService = new APIService();
export default {
  name: "HomeView",
  components: {
    FeaturedContainers,
    MapComponent,
    PageTitle,
    MemorialMapView,
    MemorialSlideGroup,
    Slider,
  },
  data() {
    return {
      homeVideDialog: false,
      memorialCount: "",
      watchVideoEmbedUrl: "",
      homePagePhotoUrl: null,
      homePageDescriptionTitle: "",
      homePageDescriptionSubtitle: "",
    };
  },
  mounted() {
    this.getMemorialCount();
      this.getWatchVideoEmbedUrl();
      this.getHomePagePhotoUrl();
      this.getHomePageDescriptionTitle();
      this.getHomePageDescriptionSubtitle();
  },
  computed: {
    splitDescriptionTitle() {
      const words = this.homePageDescriptionTitle.split(' ');
      const middleIndex = Math.floor(words.length / 2);
      const firstPart = words.slice(0, middleIndex).join(' ');
      const secondPart = words.slice(middleIndex).join(' ');

      return [firstPart, secondPart];
    },
    splitDescriptionSubtitle() {
      const words = this.homePageDescriptionSubtitle.split(' ');
      const middleIndex = Math.floor(words.length / 1.5);
      const firstPart = words.slice(0, middleIndex).join(' ');
      const secondPart = words.slice(middleIndex).join(' ');

      return [firstPart, secondPart];
    }
  },
  methods: {
    getMemorialCount() {
      apiService.getMemorialCount().then((res) => {
        this.memorialCount = res.data.data;
      });
    },
    getWatchVideoEmbedUrl() {
      apiService
        .getWebsiteConfigurationParameter("HOME_PAGE_WATCH_VIDEO_EMBED_URL")
        .then((res) => {
          this.watchVideoEmbedUrl = res.data.data;
        });
    },
    getHomePagePhotoUrl() {
      apiService
        .getWebsiteConfigurationParameter("HOME_PAGE_PHOTO_URL")
        .then((res) => {
          this.homePagePhotoUrl = res.data.data;
        });
    },
    getHomePageDescriptionTitle() {
      apiService
        .getWebsiteConfigurationParameter("HOME_PAGE_SITE_DESCRIPTION_TITLE")
        .then((res) => {
          this.homePageDescriptionTitle = res.data.data;
        });
    },
    getHomePageDescriptionSubtitle() {
      apiService
        .getWebsiteConfigurationParameter("HOME_PAGE_SITE_DESCRIPTION_SUBTITLE")
        .then((res) => {
          this.homePageDescriptionSubtitle = res.data.data;
        });
    },
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>