<template>
  <v-sheet class="bg-grey-lighten-3" fluid>
    <div class="d-flex flex-column fill-height justify-center align-center">
      <PageTitle pageTitle="Memorial Map" class="bg-grey-lighten-3" role="heading" aria-label="Section title is: Memorial Map" />
    </div>
    <MapComponent :min="value[0]" :max="value[1]" data-cy="map-component" aria-label="Interactive map displaying memorial locations" role="application" />
    <br />
    <br />
    <div class="d-flex flex-column fill-height justify-center align-center">
      <Slider
        data-cy="slider"
        v-model="value"
        :min="minimum"
        :max="maximum"
        :format="format"
        :step="step"
        :data-cy-min="value[0]"
        :data-cy-max= "value[1]"
        style="width: 95%; justify-items: center"
        aria-label="slider"
        aria-valuemin="minimum"
        aria-valuemax="maximum"
        aria-valuenow="value"
      />
    </div>
    <br />
  </v-sheet>
</template>

<script>
// @ is an alias to /src
import MapComponent from "@/components/MapComponent.vue";
import Slider from "@vueform/slider";
import PageTitle from "@/components/PageTitle.vue";
import { APIService } from "@/http/APIService";

const apiService = new APIService();

export default {
  name: "MemorialMapView",
  components: {
    PageTitle,
    props: ["showPageTitle"],
    MapComponent,
    Slider,
  },
  data() {
    // Calculate the current date in milliseconds since Epoch (Unix timestamp)
    const currentDate = new Date().getTime();

    // Calculate the start date as March 1st, 2020, in milliseconds since Epoch
    const startDate = new Date('2020-03-01').getTime();

    // Calculate the end date 
    const endDate = new Date();

    return {
      value: [startDate, currentDate], // Set the range to start date and end date
      minimum: startDate + 86400000,
      maximum: endDate.getTime(), // Set the maximum value to the end date
      step: 86400000, // Set the step to 1 day in milliseconds (86400000 milliseconds in a day)
      
      
      format: function (value) {
        return new Date(value).toDateString().split(" ").slice(1).join(" ");
      },
      showPageTitle: "",
    };
  },
    mounted() {
    this.getEndDays();
  },
  methods: {
  getEndDays() {
    apiService
      .getWebsiteConfigurationParameter("SLIDER_END_DAY_DURATION")
      .then((res) => {
        const endDays = parseInt(res.data.data);

        if (!isNaN(endDays)) {
          const currentDate = new Date();
          const endDate = new Date(currentDate);
          endDate.setDate(currentDate.getDate() + endDays); // Add days to the current date
          this.maximum = endDate.getTime();
        } else {
          // Use the default value (90 days from the current date)
          const currentDate = new Date();
          const endDate = new Date(currentDate);
          endDate.setDate(currentDate.getDate() + 90); // Add 90 days to the current date
          this.maximum = endDate.getTime();
        }
      })
      .catch((error) => {
        console.error("Error fetching end days:", error);

        // Use the default value (90 days from the current date) in case of an error
        const currentDate = new Date();
        const endDate = new Date(currentDate);
        endDate.setDate(currentDate.getDate() + 90); // Add 90 days to the current date
        this.maximum = endDate.getTime();
      });
  },
},

};
</script>

<style src="@vueform/slider/themes/default.css"></style>
